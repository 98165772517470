import React from 'react'
import "./style.css";
import Footer from "../Footer/Footer"
const Insurancedeals = () => {
  return (
    <>
    <div className="main">
     <div className="contact">
        <h1 className="title titles">Insurancedeals coming soon</h1>
        <h2 className="sub-title titles">Site Under Development</h2>
      </div>
    <div className='imgmain'>
        <img className='bannerimg' src="images4/insurancedeals4.png" alt="cartos" />
      </div>
    </div>
<div className="footercontainer">
<Footer/>
</div>    </>
  )
}

export default Insurancedeals