
import './App.css';
import Header from './Components/Header/Header';
import Insurancedeals from './Components/Insurancedeals/Insurancedeals';

function App() {
  return (
    <>
        <div className="overlay"></div>
    <div className="stars" aria-hidden="true"></div>
    <div className="starts2" aria-hidden="true"></div>
    <div className="stars3" aria-hidden="true"></div>
    <Header/>
    <Insurancedeals/>
   </>
  );
}

export default App;
